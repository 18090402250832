<template>
    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Novo suporte</h1>
                    </div>
                </div>
            </div>
        </div>
        <section class="content">
            <div class="container-fluid">
                <div class="card card-success">
                    <div class="card-header">
                        <h3 class="card-title">Suporte</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Assunto</label>
                                    <input type="text" class="form-control" placeholder="Um assunto para o que está acontendo ..." v-model="form.subject">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Descrição</label>
                                    <textarea class="form-control" rows="3" placeholder="Explique o que está acontendo ..." v-model="form.description"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer text-center">
                        <button class="btn btn-success" @click="save()">Salvar</button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import api from '../../services/api'
import { SUPPORT_INDEX } from "../../constants/api"

export default {
    name: 'Index',
    data() {
        return {
            form: {
                subject: '',
                description: '',
            }
        }
    },
    methods: {
        save() {
            api.post(SUPPORT_INDEX, this.form).then(() => {
                // TODO messages to user
                this.$router.push({ name: 'support.index'})
            })
        }
    }
}
</script>